<template>
  <div class="global-trends">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="toolbar">
      <el-form class="search-form" :inline="true" size="small">
        <el-form-item class="search-form-gutter" :label="$t('visual.when')">
          <el-select style="width: 118px" v-model="when" @change="changeDate" clearable filterable>
            <el-option v-for="item in whenList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('visual.season')">
          <el-select style="width: 138px" v-model="season" @change="changeSeason" clearable filterable>
            <el-option v-for="item in seasonList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('common.item')">
          <el-select style="width: 148px" v-model="item" clearable filterable>
            <el-option v-for="item in itemList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="search-btn">
        <el-button size="small" type="primary" icon="el-icon-search" @click="clickSearch">{{ $t("common.search")
        }}</el-button>
      </div>
      <div class="download-btn">
        <el-button size="small" class="excel-dl-btn" icon="el-icon-download" @click="downloadAll">{{
          $t("analysis.downloadAll") }}</el-button>
      </div>
    </div>
    <div class="list-content">
      <p v-if="loading">{{ $t("common.loading") }}...</p>
      <p v-else-if="tableData.length == 0">{{ $t("common.noMoreData") }}</p>
      <el-row :gutter="10" v-for="n in Math.ceil(tableData.length / 3) + 1" :key="n">
        <el-col :lg="8" v-for="data in tableData.slice((n - 1) * 3, n * 3)" :key="data.id"><el-image class="report-img"
            :src="data.thumbnailUrl" @click="download(data)" />
          <p class="title">
            {{ data.name }}
          </p>
        </el-col>
      </el-row>
    </div>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total " :pageSizes="[6, 12, 24]" @pagination="() => fetchData()" />
    <el-dialog :title="$t('common.downloading')" width="40%" :close-on-click-modal="false" :close-on-press-escape="false"
      :show-close="false" :visible.sync="dialogVisible" :before-close="downloadClick">
      <p>
        {{
          isDownloading
          ? $t("analysis.downloadProcess", {
            finishCount,
            totalCount: totalDownload,
          })
          : $t("analysis.finishDownload")
        }}
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="downloadClick">{{
          isDownloading ? $t("common.abort") : $t("common.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { fetchDate, fetchSeason, fetchVisual, fetchItem } from "@/api/visual";
import Pagination from "@/components/Pagination";
import { downloadFile } from "@/utils/download-file";
import Guide from "@/components/Guide";

export default {
  components: {
    AiBreadcrumb,
    Pagination,
    Guide,
  },
  data() {
    return {
      whenList: [],
      seasonList: [],
      itemList: [],
      when: null,
      season: null,
      item: null,
      tableData: [],
      tableRows: [],
      listQuery: {
        offset: 0,
        limit: 6,
      },
      total: 0,
      loading: false,
      finishCount: 0,
      totalDownload: 0,
      dialogVisible: false,
      isDownloading: false,
    };
  },
  created() {
    this.fetchData();
    this.fetchDate();
    this.fetchSeason();
    this.fetchItem();
  },
  methods: {
    fetchData(cb) {
      let query = this.listQuery;
      if (cb) {
        query = { limit: -1 };
      } else {
        this.tableData = [];
        this.loading = true;
      }
      query.type = this.group;
      query.date = this.when;
      query.season = this.season;
      query.item = this.item;
      fetchVisual(query)
        .then((response) => {
          if (response.success) {
            let items = response.result.items || [];
            items.forEach((e) => {
              let idx = e.url.lastIndexOf(".");
              let start = e.url.lastIndexOf("/");
              e.name = e.url.substring(start + 1);
              if (idx >= 0) {
                e.thumbnailUrl = e.url.substring(0, idx) + ".jpg";
              }
            });
            if (cb) {
              cb(items);
            } else {
              this.tableData = items;
              this.total = response.result.totalCount;
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clickSearch() {
      this.fetchData();
    },
    fetchDate() {
      fetchDate({ type: this.group }).then((response) => {
        if (response.success) {
          this.whenList = response.result.items;
        }
      });
    },
    changeDate() {
      this.season = null;
      this.item = null;
      this.fetchSeason();
      this.fetchItem();
    },
    fetchSeason() {
      fetchSeason({ type: this.group, when: this.when }).then((response) => {
        if (response.success) {
          this.seasonList = response.result.items;
        }
      });
    },
    changeSeason() {
      this.item = null;
      this.fetchItem();
    },
    fetchItem() {
      fetchItem({
        type: this.group,
        when: this.when,
        season: this.season,
      }).then((response) => {
        if (response.success) {
          this.itemList = response.result.items;
        }
      });
    },
    download(row) {
      downloadFile(row.url, row.name);
    },
    downloadAll() {
      this.fetchData((res) => {
        this.isDownloading = true;
        this.dialogVisible = true;
        this.totalDownload = res.length;
        const doDownload = (i) => {
          if (this.isDownloading && res.length > i) {
            this.finishCount = i;
            downloadFile(res[i].url, res[i].name, () => {
              doDownload(i + 1);
            });
          } else {
            this.finishCount = 0;
            this.isDownloading = false;
          }
        };
        doDownload(0);
      });
    },
    downloadClick() {
      this.isDownloading = false;
      this.dialogVisible = false;
      this.finishCount = 0;
    },
  },
  computed: {
    group() {
      if (!this.$route.meta) {
        return null;
      }
      return this.$route.meta["type"];
    },
  },
};
</script>

<style lang="scss" scoped>
.global-trends {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;

    .guide-button.hidden {
      visibility: hidden;
    }
  }

  .toolbar {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .search-form {
      float: left;
    }

    .search-btn {
      float: left;
    }

    .download-btn {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .list-content {
    text-align: center;
    font-size: 14px;
    color: #606266;
    margin-bottom: -5px;

    .report-img {
      cursor: pointer;
    }

    .title {
      margin-top: 5px;
      margin-bottom: 10px;
      line-height: 27px;
    }
  }
}
</style>